import {useState} from "react";
import "./styles.scss";
import { Typography, Box, colors, Stack } from "@mui/material";
import Hero from '../../assets/img/Hero.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppButton, AppFeature, AppTestimonials } from "../../Component";
import { features, testimonial_data } from "./data/featureData";
import { logoData } from './data/projectsData';
import { bim_logo, bim_details } from './data/bimData';
import { waka_logo, waka_payroll, waka_details, waka_pages } from './data/wakaData';
import { agri_details, agri_img1 } from './data/agriData';
import { ez_details, ez_img1, ez_logo, ez_pages } from './data/ezData';
import { cyber_details, cyber_img1 } from './data/cyberData';

export default function HomePage() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const [viewMore, setViewMore] = useState(false);
  const handleBannerClickMore = () => {
    window.location.href = "#contactUs"
  }

  return (
    <div id="home">
      
        <div className="banner">
          <div className="inner-container">
            <Typography className="title" variant="h1">
              Innovation is at the heart of everything we do
            </Typography>
            <Typography className="subTitle" variant="subtitle1">
              It is the engine that drives us forward. We're always looking for new and better ways to do things, and we're not afraid to take risks in pursuit of game-changing ideas.
            </Typography>
            <div className="buttons">
              <AppButton variant={1} onClick={() => handleBannerClickMore()} containerStyle={{marginRight: 10}} title="Contact Us"/>
              {/* <AppButton variant={2} onClick={() => {}} title="View pricing"/> */}
            </div>
            <div className="image">
              <img src={Hero} alt="" />
            </div>
          </div>
        </div>

        <div className="costumer-centric-online">
          
            <div className="content">
              <div className="inner-container">
                  <Typography className="title" variant="h2">
                    Customer-Centric Solutions
                  </Typography>
                  <Typography className="subTitle" variant="subtitle1">
                  Our solutions are not just products or services – they're experiences. We're committed to delivering a customer-centric experience that sets us apart from the competition. We provide a wide range of services tailored to cater to the unique needs of enterprise systems and tech startups. Our expertise extends beyond web and mobile application
                  development, encompassing the following key areas:
                  </Typography>
              </div>
            </div>
            <div className="features">
              {
                features.map((item) => {
                  return (
                    <div className="featureHolder">
                      <AppFeature
                      data={item}
                      />
                    </div>
                  )
                })
              }
            </div>
            
        </div>

        <div className="project-container">
          <Typography className="gradient-text" variant="h2" sx={{width:'100%',display:'block !important'}}>Products and Services</Typography>
          <Typography className="subTitle" variant="subtitle1">Transforming Ideas into Reality. Discover the cutting-edge initiatives driving our success.</Typography>
          <div className="logo-container">
            {
              logoData.map((data) => (
                <img 
                  style={{
                    width: data.width,
                  }} 
                  src={data.logo} 
                  // className="have_link"
                  // onClick={()=>window.open('https://example.com', '_blank')}
                />
              ))
            }
          </div>
        </div>

        <div className="bim-container">
          <div className="left-container">
            <Typography className="gradient-text" variant="h2" style={{textAlign: 'start'}}>BIM</Typography>
            <Typography className="subTitle" variant="subtitle1" style={{textAlign: 'start', fontSize: '16px'}}>During the maintenance period, issuing maintenance records according to contract requirements ensures proper documentation and compliance. Verifying the BIM model against collected drawing data is critical for accurate maintenance planning and execution.</Typography>
            <Typography className="subTitle" variant="subtitle1" style={{textAlign: 'start', fontSize: '16px'}}>The development phase involves constructing the overall framework, including BIM scene creation, space management, facility management, and energy consumption management. Integration with various systems requires installing servers and thorough testing after network setup. Additionally, connecting and testing IoT devices ensures seamless communication and functionality, enhancing the BIM model's capabilities for ongoing maintenance and management.</Typography>
            <Typography className="gradient-text" variant="h2" style={{textAlign: 'start', fontSize: '24px'}}>Key Features</Typography>
            <div className="key-features-content">
              {
                bim_details.key_features.map((data, index) => (
                  <div className="item-container">
                    <text className="key-features-text">{data.title}</text>
                    <text className="key-features-desc">• {data.details}</text>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="right-container">
            <img src={bim_logo} className="bim-image"/>
          </div>
        </div>

        <div className="waka-container">
            <div className="container">
              <div className="left-container">
                <img src={waka_payroll} className="waka-image"/>
              </div>
              <div className="right-container">
                <img src={waka_logo} className="waka-logo"/>
                <Typography className="subTitle" variant="subtitle1" style={{textAlign: 'start', fontSize: '16px'}}>Streamline administrative tasks by using technology to handle payroll calculations, tax withholdings, and direct deposits with precision and efficiency. This system ensures timely and accurate payment to employees while minimizing errors and reducing the burden on HR personnel.</Typography>
                <Typography className="subTitle" variant="subtitle1" style={{textAlign: 'start', fontSize: '16px'}}>Additionally, it integrates employee management functions such as attendance tracking, benefits administration, performance evaluations, and compliance reporting. By consolidating these tasks into a unified platform, organizations can enhance productivity, maintain accurate records, and focus more on strategic initiatives rather than manual processes.</Typography>
                <Typography className="gradient-text" variant="h2" style={{textAlign: 'start', fontSize: '24px'}}>Key Features</Typography>
                <div className="key-features-content">
                  {
                    waka_details.key_features.map((data, index) => (
                      <div className="item-container">
                        <text className="key-features-text">{data.title}</text>
                        <text className="key-features-desc">• {data.details}</text>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div>
            </div>
          </div>
          <Typography className="gradient-text" variant="h2" style={{textAlign: 'start', fontSize: '24px', marginTop: '20px'}}>App Screenshots</Typography>
          <div className="pages_container">
            {
              waka_pages.map((data) => (
                <div src={data} style={{width: '100%', height: '100%'}}>
                  <img src={data}/>
                </div>
              ))
            }
          </div>
        </div>

        <div className="agri-container">
          <div className="left-container">
            <Typography className="gradient-text" variant="h2" style={{textAlign: 'start'}}>AgriSense</Typography>
            <Typography className="subTitle" variant="subtitle1" style={{textAlign: 'start', fontSize: '16px'}}>AgriSense is an advanced agricultural machine designed to analyze soil and provide farmers with critical insights to enhance crop yield and soil health. Utilizing cutting-edge technology, AgriSense collects and processes soil data, offering real-time analysis on various soil parameters such as pH levels, moisture content, nutrient composition, and temperature.</Typography>
            <Typography className="gradient-text" variant="h2" style={{textAlign: 'start', fontSize: '24px'}}>Key Features</Typography>
            <div className="key-features-content">
              {
                agri_details.key_features.map((data, index) => (
                  <div className="item-container">
                    <text className="key-features-text">{data.title}</text>
                    <text className="key-features-desc">• {data.details}</text>
                  </div>
                ))
              }
            </div>
            <Stack sx={{marginTop:'20px'}}>
              <AppButton title={"Visit Website"} onClick={()=>window.open('https://agrisense.brvtechnologysg.com/', '_blank')}/>
            </Stack>
            
          </div>
          <div className="right-container">
            <img src={agri_img1} className="agri-image"/>
          </div>
        </div>

        <div className="ez-container">
            <div className="container">
              <div className="left-container">
                <img src={ez_img1} className="ez-image"/>
              </div>
              <div className="right-container">
                <img src={ez_logo} className="ez-logo"/>
                <Typography className="subTitle" variant="subtitle1" style={{textAlign: 'start', fontSize: '16px'}}>Our seamless QR order system is designed to revolutionize your dining experience. By incorporating advanced technology, we ensure that placing your order is as simple as scanning a QR code. This innovation removes the need for traditional menus and waitstaff, providing a modern, efficient way to order your favorite dishes.</Typography>
                <Typography className="subTitle" variant="subtitle1" style={{textAlign: 'start', fontSize: '16px'}}>With our QR order system, service is streamlined and significantly simplified. Once you scan the QR code, you can browse the menu, customize your order, and send it directly to the kitchen. This eliminates waiting times and reduces the chances of errors, ensuring that your dining experience is smooth and hassle-free.</Typography>
                <Typography className="gradient-text" variant="h2" style={{textAlign: 'start', fontSize: '24px'}}>Key Features</Typography>
                <div className="key-features-content">
                  {
                    ez_details.key_features.map((data, index) => (
                      <div className="item-container">
                        <text className="key-features-text">{data.title}</text>
                        <text className="key-features-desc">• {data.details}</text>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div>
            </div>
          </div>
          <Typography className="gradient-text" variant="h2" style={{textAlign: 'start', fontSize: '24px', marginTop: '20px'}}>App Screenshots</Typography>
          <div className="pages_container">
            {
              ez_pages.map((data) => (
                <div src={data} style={{width: '100%', height: '100%'}}>
                  <img src={data}/>
                </div>
              ))
            }
          </div>
        </div>

        <div className="cyber-container">
          <div className="left-container">
            <Typography className="gradient-text" variant="h2" style={{textAlign: 'start'}}>Cyber Security Audit</Typography>
            <Typography className="subTitle" variant="subtitle1" style={{textAlign: 'start', fontSize: '16px'}}>Protect Your Business with a Comprehensive Cybersecurity Audit</Typography>
            <Typography className="subTitle" variant="subtitle1" style={{textAlign: 'start', fontSize: '16px'}}>In today's digital age, safeguarding your business from cyber threats is crucial. Our Cybersecurity Audit service provides a thorough examination of your IT infrastructure to identify vulnerabilities and ensure your systems are secure.</Typography>
            <Typography className="gradient-text" variant="h2" style={{textAlign: 'start', fontSize: '24px'}}>Key Features</Typography>
            <div className="key-features-content">
              {
                cyber_details.key_features.map((data, index) => (
                  <div className="item-container">
                    <text className="key-features-text">{data.title}</text>
                    <text className="key-features-desc">• {data.details}</text>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="right-container">
            <img src={cyber_img1} className="cyber-image"/>
          </div>
        </div>

        {/* <div className="testimonials"> // hide for now
          <div className="inner-container">
            <div className="content">
              <Typography className="title" variant="h2">
                What our clients say
              </Typography>
              <Typography className="subTitle" variant="subtitle1">
                Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
              </Typography>
            </div>
            <div className="testimonial-holder">
              <Slider {...settings}>
                {
                  testimonial_data.groupBy(3).map((item) => {
                    return (
                      <div className="author-holder">
                        {
                          item.map((subData) => {
                            return (
                              <AppTestimonials data={subData} />
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
          </div>
        </div> */}

    </div>
  )
}